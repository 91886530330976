<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-card no-body>
                <b-tabs card>
                  <b-tab
                    title="Email"
                    active
                    class="pt-0"
                  >
                    <b-form-group
                      label="Email"
                      label-for="forgot-password-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="forgot-password-email"
                          v-model="userEmail"
                          :state="errors.length > 0 ? false:null"
                          name="forgot-password-email"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      @click="recovery_password"
                    >
                      Send reset link
                    </b-button>
                  </b-tab>
                  <!-- <b-tab
                    title="Phone"
                    style="styleObject"
                    class="pt-0"
                  >
                    <b-form-group
                      label="Phone"
                      label-for="forgot-password-phone"
                    >
                      <VuePhoneNumberInput
                        id="forgot-password-phone"
                        v-model="userPhone"
                        name="forgot-password-phone"
                      />
                    </b-form-group>
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      @click="forgot_password_phone"
                    >
                      Send reset link
                    </b-button>
                  </b-tab> -->
                </b-tabs>
              </b-card>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      userEmail: '',
      userPhone: '',
      sideImg: require('@/assets/images/logo/goskate_logo_hor_blue.png'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    recovery_password() {
      const data = { login: this.userEmail }
      this.$http.post('/api/profile/recovery_password', data)
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The recovery link has been sent to your email',
              },
            })
            this.$router.push('/')
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The recovery link has been sent to your email',
              },
            })
          }
        })
        .catch(error => {
          if (error.response.status === 503) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'User not found',
              },
            })
          }
        })
    },
    /* forgot_password_phone() {
      this.$http.post('/api/profile/forgot_password_phone', this.generalData)
    }, */
    validationForm() {
      this.$refs.simpleRules.validate()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
